import React, { useContext, useEffect, useState } from "react";
import Classes from "./withdrawal.module.scss";
import Icon from "../../assets/images/icons/yellowIcon.svg";
import Overview from "../Overview";
import { Col, Row, Tooltip, Tabs } from "antd";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import SocketContext from "../../context/socket";
import {
  addWithdrawal,
  getWithdrawalsByUser,
  getWithdrawalStats,
} from "../../api/services/withdrawal";
import useWithdraw from "../../contract/hooks/withdraw/useWithdraw";
import { toast } from "react-toastify";
import useReStack from "../../contract/hooks/stake/useReStack";
import { createStake, getStakesByUser } from "../../api/services/stake";
import { ReactComponent as CopyIcon } from "../../assets/images/icons/copy.svg";
import WithdrawalHistory from "../WithdrawHistory";
import ReinvestHistory from "../ReinvestHistory";
import useGetPayout from "../../contract/hooks/stake/useGetPayout";
import {
  decimalnumbervalidator,
  formatLargeNumber,
} from "../../helpers/common";
import { useSwitchNetwork, useChainId, useNetwork, useAccount } from "wagmi";
const onChange = (key) => {
  // console.log(key);
};
const items = [
  {
    key: "1",
    label: "Withdraw",
    children: <WithdrawalHistory />,
  },
  {
    key: "2",
    label: "Reinvest",
    children: <ReinvestHistory />,
  },
];
function Withdrawal() {
  const [stats, setStats] = useState({
    currentBalance: 0,
    totalReStackedAmount: 0,
    totalWithdrawal: 0,
  });
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [reStackAmount, setReStackAmount] = useState("");
  const [withdrawLoader, setWithdrawLoader] = useState(false);
  const [reStackLoader, setReStackLoader] = useState(false);
  const [stakes, setStakes] = useState(false);
  const [withdrawals, setWithdrawals] = useState(null);
  const [items, setItems] = useState([]);
  const { address } = useAccount();

  const user = useSelector((state) => state?.auth?.user);
  const { totalWithdrawal, totalPayout,availableBlnc, refetchPayout } = useGetPayout(address);
  const { chain: connectedChain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork({
    onSuccess() {
      // onSubmit()
    },
    onError(error) {
      toast.error(error?.message);
    },
  });
  const fetchWithdrawalStats = async () => {
    const res = await getWithdrawalStats();
    if (res?.data) {
      setStats(res?.data);
    }
  };
  const formatData = (data, setData) => {
    const formatedData = data.map((item) => {
      return {
        name: item?.userId?.name,
        amount: item.amount,
        walletAddress:
          item.userId?.walletAddress?.slice(0, 5) +
          "..." +
          item.userId?.walletAddress?.slice(-5),
        txHash:
          item.transactionId?.txHash?.slice(0, 5) +
          "..." +
          item.transactionId?.txHash?.slice(-5),
        txHashComplete: item.transactionId?.txHash,
      };
    });
    setData(formatedData);
  };
  const fetchAllStakesByUser = async () => {
    try {
      const res = await getStakesByUser("reStack");
      if (res?.data?.data?.length > 0) {
        formatData(res.data.data, setStakes);
      }
    } catch (err) {}
  };
  const fetchAllWithdrawalsByUser = async () => {
    try {
      const res = await getWithdrawalsByUser();
      if (res?.data?.data?.length > 0) {
        formatData(res.data.data, setWithdrawals);
      }
    } catch (err) {}
  };
  const fetchData = () => {
    fetchWithdrawalStats();
    fetchAllStakesByUser();
    fetchAllWithdrawalsByUser();
    refetchPayout();
  };
  useEffect(() => {
    fetchData();
  }, []);

  const socket = useContext(SocketContext);
  const { withdraw, withdrawSentTx, isWithdrawCompleted } = useWithdraw({
    setWithdrawLoader,
  });

  useEffect(() => {
    if (withdrawSentTx?.hash) {
      const withdrawAmount = async () => {
        const res = await addWithdrawal({
          amount: Number(withdrawalAmount),
          txHash: withdrawSentTx?.hash,
        });
      };
      withdrawAmount();
    }
  }, [withdrawSentTx]);

  const { reStack, reStackSentTx, isReStackCompleted } =
    useReStack(setReStackLoader);

  useEffect(() => {
    if (reStackSentTx?.hash) {
      const addStake = async () => {
        const res = await createStake({
          amount: Number(reStackAmount),
          txHash: reStackSentTx?.hash,
          type: "reStack",
        });
      };
      addStake();
    }
  }, [reStackSentTx]);
  useEffect(() => {
    if (isReStackCompleted) {
      // toast.success("Amount Reinvested successfully");
    }
  }, [isReStackCompleted]);
  useEffect(() => {
    if (isWithdrawCompleted) {
      // console.log('1')
      // toast.success("Withdrawal completed successfully.");
    }
  }, [isWithdrawCompleted]);
  useEffect(() => {
    if (socket && user?._id) {
      socket.emit("join", `${user?._id}`);
      const handleWithdraw = () => {
        setWithdrawLoader(false);
        fetchData();
        setWithdrawalAmount("");
        toast.success("Withdrawal completed successfully.");
      };
      const handleStake = () => {
        setReStackLoader(false);
        fetchData();
        setReStackAmount("");
        toast.success("Amount Reinvested successfully");
      };

      socket?.on("Withdraw", handleWithdraw);
      socket?.on("Stake", handleStake);
      return () => {
        socket?.off("Withdraw", handleWithdraw);
        socket?.off("Stake", handleStake);

        socket.emit("leave", `${user?.id}`);
      };
    }
  }, [socket]);
  const subtitles = [
    "Available Balance",
    "Total Withdrawal",
    "Total Re-Invested Amount",
  ];
  const titles = ["Name", "Amount", "Wallet Address", "Tx Hash"];
  useEffect(() => {
    setItems([
      {
        key: "1",
        label: "Withdraw",
        children: <WithdrawalHistory data={withdrawals} titles={titles} />,
      },
      {
        key: "2",
        label: "Reinvest",
        children: <ReinvestHistory data={stakes} titles={titles} />,
      },
    ]);
  }, [stakes, withdrawals]);
  const onWithdraw = () => {
    setWithdrawLoader(true);
    withdraw({
      args: [ethers.utils.parseEther(`${withdrawalAmount}`)],
      from: address,
    });
  };
  const onRestack = () => {
    setReStackLoader(true);
    reStack({
      args: [ethers.utils.parseEther(`${reStackAmount}`)],
      from: address,
    });
  };

  return (
    <div className={Classes.withdrawal}>
      <div className={Classes.content}>
        <div className="d-flex align-items-center gap-2 mb-4 ps-3">
          <img src={Icon} alt="icon" />
          <p className={Classes.title}>Withdrawal</p>
        </div>
        <Overview
          subtitles={subtitles}
          stats={{
            title1: formatLargeNumber(availableBlnc,4),
            title2: formatLargeNumber(totalWithdrawal,4),
            title3: formatLargeNumber(stats.totalReStackedAmount,4),
          }}
        />
        <div className={`${Classes.card} mb-4`}>
          <Row gutter={[20, 20]}>
            <Col
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className={`${Classes.border} px-md-5`}
            >
              <p className={Classes.label}>Withdraw (USDC)</p>
              <input
                className="baseInput mb-3 w-100"
                placeholder="Enter the amount"
                name="withdrawalAmount"
                value={withdrawalAmount}
                onChange={(e) => {
                  if (+e.target.value > availableBlnc) {
                    return toast.error("Insuficient Available Balance");
                  }
                  setWithdrawalAmount(e.target.value);
                }}
                onKeyDown={(e) => {
                  decimalnumbervalidator(e, 4);
                }}
              />
              <div className="d-flex justify-content-end">
                <button
                  className={Classes.btn}
                  disabled={withdrawLoader || Number(withdrawalAmount) <= 0}
                  onClick={async () => {
                    if (!address) {
                      return toast.error("Please connect wallet");
                    }
                    if (
                      Number(process.env.REACT_APP_CHAIN_ID) !==
                      connectedChain?.id
                    ) {
                      return switchNetworkAsync(
                        Number(process.env.REACT_APP_CHAIN_ID)
                      )
                        .then((res) => {
                          onWithdraw();
                        })
                        .catch((err) => {
                          toast.error(err);
                        });
   
                    }
                    onWithdraw();
                  }}
                >
                  {withdrawLoader ? "loading" : "withdraw"}
                </button>
              </div>
              {/* <div>
                <h2 className={Classes.listingTitle}>Withdraw Details</h2>
                <div className={Classes.list}>
                  <p className={Classes.label}>User Name</p>
                  <p className={Classes.field}>Sana Zafar</p>
                </div>
                <div className={Classes.list}>
                  <p className={Classes.label}>Amount</p>
                  <p className={Classes.field}>12345</p>
                </div>
                <div className={Classes.list}>
                  <p className={Classes.label}>Wallet address</p>
                  <p className={Classes.field}>0xc2...52d4</p>
                </div>
                <div className={Classes.list}>
                  <p className={Classes.label}>Transaction Hash</p>
                  <div className="d-flex align-items-center gap-2">
                    <p className={Classes.field}>0xc2...52d4</p>
                    <Tooltip title="copy">
                      <CopyIcon className={Classes.copyIcon} />
                    </Tooltip>
                  </div>
                </div>
              </div> */}
            </Col>

            {user?.isReInvestmentEnabled && (
              <Col lg={12} md={12} sm={24} xs={24} className="px-md-5">
                <p className={Classes.label}>Reinvest (USDC)</p>
                <input
                  className="baseInput mb-3 w-100"
                  placeholder="Enter the amount"
                  name="reStackAmount"
                  value={reStackAmount}
                  onChange={(e) => {
                    if (+e.target.value > totalPayout) {
                      return toast.error("Insuficient Available Balance");
                    }
                    setReStackAmount(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    decimalnumbervalidator(e, 4);
                  }}
                />
                <div className="d-flex justify-content-end">
                  <button
                    className={Classes.btn}
                    disabled={reStackLoader || Number(reStackAmount) <= 0}
                    onClick={() => {
                      if (!address) {
                        return toast.error("Please connect wallet");
                      }
                      if (
                        Number(process.env.REACT_APP_CHAIN_ID) !==
                        connectedChain?.id
                      ) {
                        return switchNetworkAsync(
                          Number(process.env.REACT_APP_CHAIN_ID)
                        )
                          .then((res) => {
                            onRestack();
                          })
                          .catch((err) => {
                            toast.error(err);
                          });
     
                      }
                      onRestack();
                    }}
                  >
                    {" "}
                    {reStackLoader ? "loading" : "Re-Invest"}
                  </button>
                </div>
                {/* <div>
                  <h2 className={Classes.listingTitle}>Reinvest Details</h2>
                  <div className={Classes.list}>
                    <p className={Classes.label}>User Name</p>
                    <p className={Classes.field}>Sana Zafar</p>
                  </div>
                  <div className={Classes.list}>
                    <p className={Classes.label}>Amount</p>
                    <p className={Classes.field}>12345</p>
                  </div>
                  <div className={Classes.list}>
                    <p className={Classes.label}>Wallet address</p>
                    <p className={Classes.field}>0xc2...52d4</p>
                  </div>
                  <div className={Classes.list}>
                    <p className={Classes.label}>Transaction Hash</p>
                    <div className="d-flex align-items-center gap-2">
                      <p className={Classes.field}>0xc2...52d4</p>
                      <Tooltip title="copy">
                        <CopyIcon className={Classes.copyIcon} />
                      </Tooltip>
                    </div>
                  </div>
                </div> */}
              </Col>
            )}
          </Row>
        </div>
        <div className={Classes.card}>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          {/* <div>
            <h2 className={Classes.listingTitle}>Withdraw History</h2>
            <div className="overflow-auto">
              <table>
                <tr>
                  <th>Username</th>
                  <th>Amount</th>
                  <th>Wallet addres</th>
                  <th>Transaction Hash</th>
                </tr>
                <tr>
                  <td>
                    <p className={Classes.field}>Sana Zafar</p>
                  </td>
                  <td>
                    <p className={Classes.field}>12345</p>
                  </td>
                  <td>
                    <p className={Classes.field}>0xc2...52d4</p>
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <p className={Classes.field}>0xc2...52d4</p>
                      <Tooltip title="copy">
                        <CopyIcon className={Classes.copyIcon} />
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Withdrawal;

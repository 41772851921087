import React, { useEffect, useState } from "react";
import Classes from "./sidebar.module.scss";
import { Menu } from "antd";
import Logo from "../../../assets/images/section/logo.svg";
import { ReactComponent as DashboardIcon } from "../../../assets/images/icons/dashboard.svg";
import { ReactComponent as Protocol } from "../../../assets/images/icons/protocol.svg";
import { ReactComponent as Investment } from "../../../assets/images/icons/investment.svg";
import { ReactComponent as Polls } from "../../../assets/images/icons/investment.svg";
import { ReactComponent as User } from "../../../assets/images/icons/user.svg";
import { Link, useLocation } from "react-router-dom";

const SidebarComponent = ({ collapsed }) => {
  const [showName, setShowName] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const location = useLocation(); // to get current route

  useEffect(() => {
    // Retrieve the selected key from localStorage when the component mounts
    const storedKey = localStorage.getItem("selectedKey");
    if (location.pathname) {
      const routeKey = menuItems.find(item => item.label.props.to === location.pathname)?.key;
      if (routeKey) {
        setSelectedKey(routeKey);
      }else {
        setSelectedKey("1")
      }
      
    } else {
      setSelectedKey(storedKey)
    }
  }, [location.pathname]);

  useEffect(() => {
    let timeoutId;
    if (!collapsed) {
      timeoutId = setTimeout(() => {
        setShowName(true);
      }, 100);
    } else {
      setShowName(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [collapsed]);

  const handleMenuSelect = (e) => {
    const { key } = e;
    setSelectedKey(key);
    // Save the selected key to localStorage
    localStorage.setItem("selectedKey", key);
  };

  const menuItems = [
    {
      key: "1",
      icon: <DashboardIcon />,
      label: <Link to="/dashboard">Main Dashboard</Link>,
      title: null,
    },
    {
      key: "2",
      icon: <DashboardIcon />,
      label: <Link to="/your-dashboard">Your Dashboard</Link>,
      title: null,
    },
    {
      key: "3",
      icon: <Protocol />,
      label: <Link to="/protocol-metrics">Protocol Metrics</Link>,
      title: null,
    },
    {
      key: "4",
      icon: <User />,
      label: <Link to="/profile">Profile</Link>,
      title: null,
    },
    {
      key: "5",
      icon: <Protocol />,
      label: <Link to="/withdrawal">Withdrawal</Link>,
      title: null,
    },
  ];

  return (
    <aside className={Classes.sidebar}>
      <Link
        to="/"
        className={`${Classes.logo} d-flex align-items-center gap-1 pb-3 px-3`}
      >
        <img src={Logo} alt="logo" />
        {showName && <p className={Classes.name}>The Warriors DAO</p>}
      </Link>
      <Menu
        selectedKeys={[selectedKey]}  // Set the selected key
        onSelect={handleMenuSelect}    // Handle selection and save key to localStorage
        className="px-3"
        items={menuItems}
      />
    </aside>
  );
};

export default SidebarComponent;

import React, { useEffect, useState } from "react";
import Classes from "./payout.module.scss";
import Overview from "../Overview";
import { getPlatformStats } from "../../api/services/dashboardStats";
import useGetPlatformStats from "../../contract/hooks/stake/useGetPlatformInfo";
import useGetPoolAmount from "../../contract/hooks/stake/useGetPoolAmount";
import Leaf from "../../assets/images/icons/yelowLeaf.svg";
import { Switch, Checkbox } from "antd";
import ConfirmModal from "../../models/ConfirmModal";

const onChange = (checked) => {
  // console.log(`switch to ${checked}`);
};
const onCheckChange = (e) => {
  // console.log(`checked = ${e.target.checked}`);
};
function Payout() {
  const [stats, setStats] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsModalOpen(checked);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const subtitles = [
    "total payout Amount",
    "Total payout from treasury pool",
    "total payout from ownership pool ",
  ];
  useEffect(() => {
    const getStats = async () => {
      const res = await getPlatformStats();
      setStats(res?.data);
    };
    getStats();
  }, []);
  const { treasurePoolPercentage, ownershipPoolPercentage, investment } =
    useGetPlatformStats();
  const { ownerShipPool, treasurePool } = useGetPoolAmount();
  return (
    <>
      <div className={Classes.payout}>
        <div className={Classes.content}>
          <div className="d-flex align-items-center gap-2 mb-4 ps-3">
            <img src={Leaf} alt="icon" />
            <p className={Classes.title}>Payout</p>
          </div>
          <Overview
            subtitles={subtitles}
            stats={{
              title1: investment,
              title2: treasurePool,
              title3: ownerShipPool,
            }}
          />
          <div className={`${Classes.card} mx-3`}>
            <h2 className={Classes.cardtitle}>Payout</h2>
            <div className="d-flex align-items-center gap-4 mb-4">
              <p className={Classes.txt}>Enable / Disable</p>
              <Switch onClick={handleSwitchChange} onChange={onChange} />
            </div>
            <div className={Classes.bgCheckbox}>
              <div className="d-flex align-items-center gap-3 mb-3">
                <Checkbox onChange={onCheckChange} />
                <span className={Classes.name}>Daily</span>
              </div>
              <div className="d-flex align-items-center gap-3 mb-3">
                <Checkbox onChange={onCheckChange} />
                <span className={Classes.name}>Weekly</span>
              </div>
              <div className="d-flex align-items-center gap-3">
                <Checkbox onChange={onCheckChange} />
                <span className={Classes.name}>Monthly</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </>
  );
}

export default Payout;

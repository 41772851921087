// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import { CONTRACT_INFO } from "../../index";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { extractErrorMessage } from "../../../helpers/common";
const reStackContractAddress = CONTRACT_INFO.stake.address;
const abi = CONTRACT_INFO.stake.abi;

const useReStack = (setLoader) => {
  // approve contract

  const {
    isError: isReStackSentError,
    data: reStackSentTx,
    isLoading: isReStackTxInProgress,
    isSuccess: isReStackTxSent,
    write: reStack,
    error: reStackSentError
  } = useContractWrite({
    address: reStackContractAddress,
    abi,
    functionName: "reStakeTokens",
  });

  const {
    isLoading: isReStackWaiting,
    isSuccess: isReStackCompleted,
    isError: isReStackError,
    error: reStackTxError,
    data: reStackResp
  } = useWaitForTransaction({
    hash: reStackSentTx?.hash,
  });

  useEffect(()=>{
    const error=reStackSentError||reStackTxError
    if(error){
        toast.error(extractErrorMessage(error?.message))
        if(setLoader){
            setLoader(false)
        }
    }

  },[isReStackSentError,isReStackError])

  return {
    // reStack operation
    isReStackSentError,
    reStackSentTx,
    isReStackTxInProgress,
    isReStackTxSent,
    reStack,
    isReStackCompleted,
    isReStackError,
    isReStackWaiting,
    reStackSentError,
    reStackTxError,
    reStackResp
  };
};

export default useReStack;

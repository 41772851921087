// @ts-ignore
import { SET_WALLET_ADDRESS,SET_USER_DATA,SET_USER_PROFILE_MODAL_STATUS } from '../constants';
export const setWalletAddress = (address) => {
  return {
    type: SET_WALLET_ADDRESS,
    payload: address,
  };
};
export const setUserData = (data) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};
export const setUserProfileModalStatus = (data) => {
  return {

    type: SET_USER_PROFILE_MODAL_STATUS,
    payload: data,
  };
};

// export const userNotificationList = (data, callback) => {
//   return async (dispatch) => {
//     try {
//       await dispatch(notifications(data));
//       // callback();
//     } catch (error) {
//       console.log('Error: ', error.message);
//       console.log('Error: ', error);
//     }
//   };
// };

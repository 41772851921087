// DashboardLayout.js
import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import HeaderComponent from "./Header";
import Sidebar from "./Sidebar";
import Classes from "./dashboardLayout.module.scss";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import ProfileModal from "../../models/ProfileModal";
const { Header, Sider, Content } = Layout;

const DashboardLayout = (props) => {
  const [collapsed, setCollapsed] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 575 });
  const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isUserProfileModalOpen = useSelector((state) => state.auth.isUserProfileModalOpen);
  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    } else if (isTablet) {
      setCollapsed(true);
    } else if (isDesktop) {
      setCollapsed(false);
    }
  }, [isMobile, isTablet, isDesktop]);

  const sidebarWidth = collapsed ? 80 : isMobile || isTablet ? 200 : 245;

  const headerStyle = {
    left: sidebarWidth,
    width: `calc(100% - ${sidebarWidth}px)`,
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className={Classes.layout}>
      <Sider
        className={Classes.sider}
        trigger={null}
        breakpoint="lg"
        collapsedWidth="0"
        collapsible
        collapsed={collapsed}
        onBreakpoint={(broken) => {
          setCollapsed(broken);
        }}
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed);
        }}
      >
        <Sidebar collapsed={collapsed} />
      </Sider>
      <Layout style={{ marginLeft: sidebarWidth }}>
        <Header
          className={`${Classes.headerOuter} d-flex align-items-center`}
          style={headerStyle}
        >
          <HeaderComponent
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
          />
        </Header>
        <Content className={Classes.mainOuter}>
          {/* <Outlet />  */}
          {/* Render nested routes here */}
          {props.children}
        </Content>
        {/* OPEN MODAL BASED ON isUserProfileModalOpen Flag */}
        <ProfileModal isModalOpen={isUserProfileModalOpen} />
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;

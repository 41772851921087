import React, { useEffect, useState } from "react";
import Classes from "./investment.module.scss";
import Icon from "../../../assets/images/icons/leaf.svg";
import { Col, Row } from "antd";
import WarImg from "../../../assets/images/section/war.svg";
import Piechart from "../../PieChart";
import NoData from "../../../assets/images/section/noData.svg";
import { colors } from "../../../data";
import { formatLargeNumber } from "../../../helpers/common";
function Investment({ stats }) {
  const [pieChartData, setPieChartData] = useState(null);
  const { investment, percentages, totalProjectInvestment } = stats || {};

  useEffect(() => {
    if (percentages) {
      const formatedData = percentages.map((project, i) => {
        return {
          name: project.name,
          y: Number(project.percentage?.toFixed(2)),
          // color:getRandomColor(),
          ...colors[i],
        };
      });
    
      setPieChartData(formatedData);
    }
  }, [percentages]);
  return (
    <div className={Classes.mainInvestment}>
      <div className={Classes.header}>
        <img src={Icon} alt="icon" />
        <p>Pool</p>
      </div>
      <Row gutter={[10, 10]}>
        <Col lg={17} md={24} xs={24}>
          <div className="px-0">
            {/* {pieChartData && pieChartData?.length === 0 && (
              <img className="py-4" src={NoData} alt="img" />
            )} */}
            {pieChartData && pieChartData?.length > 0 && (
              <Piechart data={pieChartData} />
            )}
          </div>
        </Col>
        <Col xl={7} lg={24} md={24} xs={24}>
          <div
            className={`${Classes.totalInvestment} py-3 d-flex flex-column justify-content-center ${pieChartData && pieChartData.length > 0 ? Classes.mgTop : ""}`}
          >
            <img height={140} src={WarImg} alt="img" />
            <div className="text-center py-4">
              <span className={Classes.txt}>Total Pool Cap</span>
              <h1 className={Classes.price}>{`${formatLargeNumber( totalProjectInvestment,2) || "0"} USDC`}</h1>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-1">

            </div>
          </div>
        </Col>
      </Row>
      <div
        className={`${Classes.projectList} d-flex align-items-center gap-3 mt-3 flex-wrap`}
      >
        {pieChartData &&
          pieChartData?.map((project) => {
            return (
              <div className="d-flex flex-column gap-4">
                <div className={Classes.graphInfo}>
                  <div
                    className={`${Classes.box} `}
                    style={{ background: project?.color }}
                  ></div>
                  <span> {project?.name}</span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Investment;
